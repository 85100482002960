
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        





























































































@import '@/styles/_colors.scss';

.objective {
  display: flex;
  flex-wrap: wrap;
}

.key-results {
  margin-top: 2.5rem;
}

.key-results__list {
  margin: 1.5rem 0;
  background: white;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(black, 0.1);
}

.key-results__list--row {
  border-top: 1px solid $color-grey-100;

  &:first-child {
    border-top: 0;
  }
}

.objective__heading {
  display: flex;
}

.objective__heading-text {
  max-width: 46rem;
  margin-right: 2.5rem;
}

.objective__icon {
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  margin-right: 0.5rem;
  margin-left: auto;
  padding: 0.5rem 1.5rem;
  color: var(--color-text-secondary);
  font-size: 1.15rem;
  text-align: center;
  background: var(--color-primary);
  border-radius: 2rem;
}
